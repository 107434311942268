.loader-container{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #F8F8F8AD;
  }
  .loader{
    left: 50%;
    top: 30%;
    z-index: 1000;
    position: absolute;
  }