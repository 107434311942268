.modal-header {
  padding:9px 15px;
  border-bottom:1px solid #eee;
  color:#fff;
  background-color: #0480be;
  -webkit-border-top-left-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-topright: 5px;
   border-top-left-radius: 5px;
   border-top-right-radius: 5px;
}


.btn-alert-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
  box-shadow: none;
}

.custom-ui {
  text-align: center;
  width: 500px;
  padding: 40px;
  background: #28bae6;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
  color: #fff;
}

.custom-ui > h1 {
  margin-top: 0;
}

.custom-ui > button {
  width: 160px;
  padding: 10px;
  border: 1px solid #fff;
  font-weight: 500;
  margin: 10px;
  cursor: pointer;
  font-size: 16px;
}

.scrollbar {
  margin-left: 5px;
  float: left;
  height: 300px;
  width: 99%;
  background: #fff;
  overflow-y: scroll;
  margin-bottom: 25px;
  padding: 10px;
  }
  .force-overflow {
  min-height: 100%;
  }
  
  .scrollbar-primary::-webkit-scrollbar {
  width: 7px;
  background-color: #F5F5F5; }
  
  .scrollbar-primary::-webkit-scrollbar-thumb {
  border-radius: 10px;
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1); */
  background-color: #4285F4; }
  
  .scrollbar-primary {
  scrollbar-color: #4285F4 #F5F5F5;
  }


.chatuser
{
  border:1px solid skyblue;
  display: flex;
  padding: 5px 0 0 5px;
  margin-right: 1px;
  background-color: honeydew;
  margin-top:1px;
  box-shadow: rebeccapurple;
}

/* .chatuser :hover
{
  background-color: snow;
} */

.chatuser img
{
  height: 1.5rem;
  width: 1.5rem;
}

.chatuser .info {
  display: inline-block;
  padding: 2px 0 0 5px;
}
  
  .jumbotron {
    padding: 2rem 2rem;
}

